import { Gizmo } from "flowy-3-core";
import { Row } from "antd";
import { quintanaRooFaro } from "../../assets/images";

type LoginHeaderProps = {
  gizmo: Gizmo;
};

const LoginHeader = ({ gizmo }: LoginHeaderProps) => {
  return (
    <Row justify={"center"}>
      <img
        src={quintanaRooFaro}
        style={{
          width: "25%",
        }}
      />
    </Row>
  );
};

export default LoginHeader;
