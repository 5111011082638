import { FC, useEffect, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import { Image } from "antd";
import { GizmoWrapper } from "../../utils";
import transformFiles from "../../daq/FileField/transformFiles";

type MultimediProps = {
  gizmo: Gizmo;
};

const Multimedia: FC<MultimediProps> = ({ gizmo }) => {
  const { binder, features, errors } = useGizmo({ gizmo });
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    if (binder) {
      const files = binder.getFiles();

      if (files.length > 0) {
        transformFiles(files).then((f) => {
          setFileList(f);
        });
      }
    }
  }, [binder]);

  return (
    <GizmoWrapper features={features} errors={errors}>
      {fileList.length === 0 && <div>No hay archivos</div>}
      {fileList.length === 1 && (
        <Image
          src={fileList[0].thumbUrl}
          style={{ width: "50%" }}
        />
      )}
    </GizmoWrapper>
  );
};

export default Multimedia;
