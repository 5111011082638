import { Html5QrcodeScanner } from "html5-qrcode";
import React, { useEffect } from "react";

const qrcodeRegionId = "html5qr-code-full-region";

// Define the type for the component props
interface Html5QrcodePluginProps {
  fps?: number;
  qrbox?: number | { width: number; height: number };
  aspectRatio?: number;
  disableFlip?: boolean;
  verbose?: boolean;
  qrCodeSuccessCallback: (decodedText: string, decodedResult: any) => void;
  qrCodeErrorCallback?: (errorMessage: string) => void;
}

const createConfig = (props: Html5QrcodePluginProps): any => {
  let config: any = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

const Html5QrcodePlugin: React.FC<Html5QrcodePluginProps> = (props) => {
  useEffect(() => {
    const config = createConfig(props);
    const verbose = props.verbose === true;

    if (!props.qrCodeSuccessCallback) {
      throw new Error("qrCodeSuccessCallback is a required callback.");
    }

    const html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose
    );
    html5QrcodeScanner.render(
      props.qrCodeSuccessCallback,
      props.qrCodeErrorCallback
    );

    const timeoutId = setTimeout(() => {
      const startScanningButton = document.getElementById(
        "html5-qrcode-button-camera-start"
      );

      if (startScanningButton) {
        startScanningButton.textContent = "Iniciar escaneo";
        console.log("Texto cambiado a 'Iniciar escaneo'");
      } else {
        console.log("No se encontró el botón de inicio.");
      }
    }, 2000);

    const intervalId = setInterval(() => {
      const stopScanningButton = document.getElementById(
        "html5-qrcode-button-camera-stop"
      );
      const selectFileButton = document.getElementById(
        "html5-qrcode-anchor-scan-type-change"
      );
      const permission = document.getElementById(
        "html5-qrcode-button-camera-permission"
      );
      const permissionMessage = document.getElementById(
        "html5qr-code-full-region__header_message"
      );

      if (stopScanningButton) {
        stopScanningButton.textContent = "Detener escaneo";
      }

      if (permission) {
        permission.textContent = "Soliciar permisos";
      }

      if (permissionMessage) {
        permissionMessage.textContent = "Solicitando permisos de cámara...";
      }

      if (selectFileButton) {
        // selectFileButton.style.display = "none !important";
        selectFileButton.style.visibility = "hidden";
        console.log("selectFileButton ocultado.");
      }

      if (stopScanningButton && selectFileButton) {
        clearInterval(intervalId);
      }
    }, 100);

    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error("Failed to clear html5QrcodeScanner.", error);
      });
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [props]);

  return <div id={qrcodeRegionId} />;
};

export default Html5QrcodePlugin;
