interface IConfig {
  /**
   * API Url
   */
  apiUrl: string;
  auth: {
    /**
     * Authentication method, options:
     * 	- firebase: authenticate with Firebase, set configuration object in config
     * 	- jwt: authenticate against the API with JWT token
     */
    method: "firebase" | "jwt" | "cognito";
    /**
     * Configuration of the authentication method
     */
    config?: any;
  };
}

const config: IConfig = {
  // apiUrl: process.env.REACT_APP_API_URL || "https://vdv0g8jtc3.execute-api.us-east-1.amazonaws.com",
  apiUrl: process.env.REACT_APP_API_URL || "https://kong-cluster.wsflowz.com",
  auth: {
    method: "jwt",
  },
};

export default config;
