import { DatePicker, TimePicker } from "antd";
import styled from "styled-components";

const DateField = styled(DatePicker)`
  width: 100%;
`;

const TimeField = styled(TimePicker)`
  width: 100%;
`;

export { DateField, TimeField };
