import { FC, useEffect, useRef, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Image,
  Modal,
  Popconfirm,
  Row,
  Checkbox,
  Spin,
} from "antd";
import { GizmoWrapper } from "../../utils";
import * as S from "./IdOcrField.styles";
import transformFiles from "../FileField/transformFiles";
import ImageCapture from "./ImageCapture";
import {
  applyPolyfills,
  defineCustomElements,
} from "@microblink/blinkid-in-browser-sdk/ui/loader";
import { fileToBase64, getFileFromImageData } from "../../utils/functions";
import { translations } from "./translations";

function getAddressDetails(address) {
  let street, location, neighborhood, zipcode, municipality, state;

  if (address) {
    const data = address.split("\n");

    if (data.length > 2) {
      try {
        street = data.slice(0, -2).join(" ");
      } catch (e) {
        console.log("getAddress.eeeeeeeeee: ", e);
      }
      if (data[data.length - 2] && data[data.length - 1]) {
        try {
          [location, neighborhood, zipcode] =
            data[data.length - 2].match(/^(.*) (\d+)$/m);
        } catch (e) {}
        try {
          [municipality, state] = data[data.length - 1].split(",");
        } catch (e) {}
      }
    }
  }

  return {
    address,
    street,
    location,
    neighborhood,
    zipcode,
    municipality,
    state,
  };
}

const IdOcrField = ({ gizmo }) => {
  const { features, errors, binder, config } = useGizmo({ gizmo });
  const [frontFileList, setFrontFileList] = useState([]);
  const [backFileList, setBackFileList] = useState([]);
  const [frontFileLoading, setFrontFileLoading] = useState(false);
  const [backFileLoading, setBackFileLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState({
    type: "",
    uri: "",
  });
  const [capture, setCapture] = useState({
    side: undefined,
    setFileList: ([]) => {},
  });
  const [captureActive, setCaptureActive] = useState(false);
  const [onlyFront, setOnlyFront] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [frontFile, setFrontFile] = useState();
  const [backFile, setBackFile] = useState();
  const [faceFile, setFaceFile] = useState();
  const [scannerEnabled, setScannerEnabled] = useState(true);

  useEffect(() => {
    if (binder) {
      const onlyFront = binder.getOnlyFront();
      if (onlyFront !== undefined) {
        setOnlyFront(onlyFront);
      }
      const files = binder.getFiles();
      if (files?.front) {
        transformFiles([files.front]).then((res) => {
          setFrontFileList(res);
        });
      }
      if (files?.back) {
        transformFiles([files.back]).then((res) => {
          setBackFileList(res);
        });
      }
      if (features?.idOcrField?.scannerEnabled === false) {
        setScannerEnabled(false);
      }
    }
  }, [binder]);

  // Reference to the `<blinkid-in-browser>` custom web component
  const el = useRef(null);
  const initializeBlinkIdInBrowser = () => {
    applyPolyfills().then(() => {
      defineCustomElements().then(async () => {
        if (!el.current) {
          return;
        }
        // proto-client license key
        el.current.licenseKey = "sRwCABtmYXJvLmZvcmFjYy5iaWVuZXN0YXJxci5jb20GbGV5SkRjbVZoZEdWa1QyNGlPakUzTXpFMk1USTJORFk1TURNc0lrTnlaV0YwWldSR2IzSWlPaUkwTWpVNE5UWTVNeTFoWXpNekxUUTFZalF0WW1abFl5MDVPV1JqT0dZeVptUmhPR1FpZlE9PcMUdPrHN/9jwd5+jJ/NLTUVKgueYOfL3Cz2R3MRvg4VpSbUNEOX/0uW66ar92k0QvTVCcd9kOGxuRcvnjyaqM9DKHjNGXLU5usDjaho4NHmv42LFEBGepbYnAvke/0ZfmzUQfYhSjcsfJ8jGRFrCPNlDy8=";
        el.current.recognizers = ["BlinkIdMultiSideRecognizer"];

        // Engine location depends on the actual location of WebAssembly resources
        el.current.engineLocation = window.location.origin + "/resources";

        el.current.recognizerOptions = {
          BlinkIdMultiSideRecognizer: {
            returnFullDocumentImage: true,
            returnFullDocumentFrontImage: true,
            returnFullDocumentBackImage: true,
            returnFaceImage: true,
            returnSignatureImage: true,
          },
        };
        el.current.scanFromImage = false;
        el.current.allowHelpScreensFab = false;
        el.current.allowHelpScreensOnboarding = false;

        el.current.translations = translations;

        el.current.addEventListener("ready", (ev) => {
          /* console.log("ready.1: ", ev.details); */
        });

        el.current.addEventListener("scanSuccess", async (ev) => {
          console.log("scanSuccess.1: ", ev);
          console.log("scanSuccess.2: ", ev?.detail?.recognizer);
          if (ev?.detail?.recognizer) {
            await binder.setOcrResult(ev.detail.recognizer);
            const files = binder.getFiles();
            if (files?.front) {
              transformFiles([files.front]).then((res) => {
                setFrontFileList(res);
              });
            }
            if (files?.back) {
              transformFiles([files.back]).then((res) => {
                setBackFileList(res);
              });
            }
          }
        });

        el.current.addEventListener("scanError", (ev) => {
          console.log("scanError", ev.details);
        });

        el.current.addEventListener("fatalError", (ev) => {
          console.log("fatalError", ev.details);
        });

        console.log('--------------------------in->el: ', el);
      });
      console.log('--------------------------out');
    });
  }

  useEffect(() => {
    const initialFeatures = gizmo.getFeatures();
    if (initialFeatures.editable === true) {
      initializeBlinkIdInBrowser();
    }
    gizmo.features.subscribe((features) => {
      if (features.editable === true) {
        initializeBlinkIdInBrowser();
      }
    });
  }, []);

  const handleBeforeUpload = async (file, side) => {
    if (side === "front") {
      setFrontFileLoading(true);
      await binder.setFile("front", file);
      setFrontFileList(await transformFiles([file]));
      setFrontFileLoading(false);
    } else if (side === "back") {
      setBackFileLoading(true);
      await binder.setFile("back", file);
      setBackFileList(await transformFiles([file]));
      setBackFileLoading(false);
    }
    return false;
  };

  const handlePreview = async (file, side) => {
    if (side === "front") {
      const f = frontFileList[0];
      setPreviewFile({ type: "image", uri: f.thumbUrl });
    } else if (side === "back") {
      const f = backFileList[0];
      setPreviewFile({ type: "image", uri: f.thumbUrl });
    }

    setPreviewOpen(true);
  };

  const handleRemove = (file, side) => {
    if (side === "front") {
      const index = frontFileList.indexOf(file);
      const newFileList = frontFileList.slice();
      newFileList.splice(index, 1);
      setFrontFileList(newFileList);
    } else if (side === "back") {
      const index = backFileList.indexOf(file);
      const newFileList = backFileList.slice();
      newFileList.splice(index, 1);
      setBackFileList(newFileList);
    }
  };

  const handleTakePhoto = (side, setFileList) => {
    setCapture({ side, setFileList });
    setCaptureActive(true);
  };

  const handleOnlyFrontCheckbox = async (e) => {
    setProcessing(true);
    await binder.setOnlyFront(e.target.checked);
    setOnlyFront(e.target.checked);
    setProcessing(false);
  };

  const showFrontFileControl =
    !captureActive &&
    frontFileList < 1 &&
    (config.ops?.idOcrField?.inputSource === "both" ||
      config.ops?.idOcrField?.inputSource === "local_file");
  const showFrontCameraControl =
    !captureActive &&
    frontFileList.length < 1 &&
    (config.ops?.idOcrField?.inputSource === "both" ||
      config.ops?.idOcrField?.inputSource === "camera");
  const showBackFileControl =
    !captureActive &&
    backFileList < 1 &&
    (config.ops?.idOcrField?.inputSource === "both" ||
      config.ops?.idOcrField?.inputSource === "local_file") &&
    !onlyFront;
  const showBackCameraControl =
    !captureActive &&
    backFileList.length < 1 &&
    (config.ops?.idOcrField?.inputSource === "both" ||
      config.ops?.idOcrField?.inputSource === "camera") &&
    !onlyFront;

  return (
    <GizmoWrapper features={features} errors={errors}>
      {frontFileList.length === 0 && !features.editable && (
        <>
          <i>No se cargaron archivos</i>
          <br />
          <br />
        </>
      )}
      {scannerEnabled && features.editable && (
        <blinkid-in-browser ref={el}></blinkid-in-browser>
      )}
      <Image src={faceFile} width={100} />
      <Image src={frontFile} width={200} />
      <Image src={backFile} width={200} />

      {(frontFileList.length > 0 || features.editable) && (
        <Row>
          <Col>
            <Row>
              <S.FrontImageFile
                showUploadList={{
                  showRemoveIcon: scannerEnabled === false && features.editable,
                  // showRemoveIcon: features.editable,
                  removeIcon: (
                    <Popconfirm
                      title="Eliminar archivo"
                      description="¿Está seguro que desea eliminar el archivo?"
                      okText="Si"
                      cancelText="No"
                      onConfirm={(e) => handleRemove(e, "front")}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  ),
                }}
                beforeUpload={(file) => handleBeforeUpload(file, "front")}
                fileList={frontFileList}
                listType="picture-card"
                onPreview={(file) => handlePreview(file, "front")}
                accept="image/jpeg,image/png"
                disabled={frontFileLoading}
              >
                {/* {showFrontFileControl && ( */}
                {scannerEnabled === false && showFrontFileControl && (
                  <div>
                    {frontFileLoading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div>Archivo frente</div>
                  </div>
                )}
              </S.FrontImageFile>
            </Row>
            {showFrontCameraControl && (
              <Button
                onClick={() => handleTakePhoto("front", setFrontFileList)}
              >
                Tomar foto frente
              </Button>
            )}
          </Col>
          <Col>
            <Row>
              <S.BackImageFile
                showUploadList={{
                  showRemoveIcon: scannerEnabled === false && features.editable,
                  // showRemoveIcon: features.editable,
                  removeIcon: (
                    <Popconfirm
                      title="Eliminar archivo"
                      description="¿Está seguro que desea eliminar el archivo?"
                      okText="Si"
                      cancelText="No"
                      onConfirm={(e) => handleRemove(e, "back")}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  ),
                }}
                beforeUpload={(file) => handleBeforeUpload(file, "back")}
                fileList={backFileList}
                listType="picture-card"
                onPreview={(file) => handlePreview(file, "back")}
                accept="image/jpeg,image/png"
                disabled={backFileLoading}
              >
                {/* {showBackFileControl && ( */}
                {scannerEnabled === false && showBackFileControl && (
                  <div>
                    {backFileLoading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div>Archivo reverso</div>
                  </div>
                )}
              </S.BackImageFile>
            </Row>
            {showBackCameraControl && (
              <Button onClick={() => handleTakePhoto("back", setBackFileList)}>
                Tomar foto reverso
              </Button>
            )}
          </Col>
        </Row>
      )}

      {captureActive && (
        <ImageCapture
          binder={binder}
          capture={capture}
          captureActive={captureActive}
          setCaptureActive={setCaptureActive}
        />
      )}
      {/* {frontFileList.length > 0 && backFileList.length === 0 && (
        <Checkbox onChange={handleOnlyFrontCheckbox} checked={onlyFront}>
          No cuento con el reverso de la credencial
        </Checkbox>
      )} */}
      {processing && <Spin />}

      <Modal
        open={previewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        style={{ top: 20 }}
        width={800}
      >
        <Image src={previewFile.uri} preview={false} />
      </Modal>
    </GizmoWrapper>
  );
};

export default IdOcrField;
